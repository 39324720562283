.testCarousel{
    position: relative;
    width: 90%;
    border-radius: 25px;
    background-color: #009688;
}
.carousel-inner{
	position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.carousel-item{
    height: 100%;
}
.MuiAvatar-root.testAvatar{
    width: 100px;
    height: 100px;
    /* transform: translate(-50%, 0); */
    margin: 0 auto;
}
.carousel-caption{
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #fff;
        display: flex;
        text-align: center;
        margin: 0 15%;
        height: calc(95% - 84px);
        flex-direction: column;
        margin-top: 44px;
        margin-bottom: 40px;
        justify-content: center;
        row-gap: 20px;
        position: static !important;
    }
.abc{
        font-size: 17px;
        line-height: 40px;
}