/*For Mobile Devicess*/
@media screen and (max-width: 480px) {
    .contactGrid{
      background-color: rgb(255, 255, 255);
      margin-bottom: 200px;
      border: 5px solid rgb(255, 255, 255);
      height: 400px;
      padding: 00px;
    }
    .gridImg{
      background-color: rgb(255, 255, 255);
      /* margin-bottom: 200px;
      border: 5px solid rgb(255, 255, 255); */
      height: 100%;
      width: 100%;
      /* padding-top: 50px; */
    }
  
  }
  .contactBackgroundImg{
    width:100%;
    height:300px;
  }
  
  
  .gridB{
    background-color: rgb(255, 255, 255);
    margin: 10px;
    height: 0px;
    padding: 20px;
  }
  .MuiFormControl-root.controlForm{
    margin:0 15px 32px  0;
    border:0px;
    width: 100%;
    
  }
  .MuiButtonBase-root.butOnSubmit{
    background-color: #009688;
    width: 100px;
    font-weight: bolder;
  }
  .MuiButtonBase-root.butOnSubmit:hover{
    background-color: #00695f;
    width: 100px;
    font-weight: bolder;
  }
  
  .contactLabel{
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 15px;
    color:#00695f;
  
  }
  .googleMap{
    margin-top: 100px;
    width: 100%;
    height: 450px ;
  }
  
  .flexBoxImg{
  padding-left: 10%;
  
  }
  .flexBox{
    text-align: center;
    background-color: #2c2b38;
    margin-bottom: 0px;
    border: 5px solid rgb(255, 255, 255);
    height: 290px;
    padding: 0;
  }
  
  .topCls{
    font-weight: bold;
    color: lightgray;
    padding: 30px 0px 15px 0px;
  }
  
  .midCls{
    font-size: 1.4rem;
    font-weight: bold;
    color: lightgray;
    padding: 0px 0px 5px 0px;
  }
  
  .botCls{
    font-size: 1rem;
    font-weight:normal;
    color: rgb(255, 255, 255);
    padding: 0px 0px 15px 0px;
    line-height: 35px;
  }
  .contactBoxes{
    padding: 7px 25px;
    box-sizing: border-box;
    border-radius: 50px;
    background-color: #009688;
  }
  .MuiGrid-root.contactBoxes{
    background-color: #009688;
  }