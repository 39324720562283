.footContent{
    padding: 20px;
    background-color: #00695f;
    margin-top: 100px;
    font-family: 'NTR', sans-serif;
}
.fooTitle{
    font-size: larger;
    font-weight: 400;
    color: rgb(99, 99, 99);
}
.fooBody{
    font-size: large;
    color: aliceblue;
    padding: 0px;
    margin: 0px;
}
.footerStack{
    height: 150px;
    align-items: center;
    justify-content: center;
    column-gap: 22px;
}
.footerStack1{
    height:150px;
    align-items:center;
    justify-content: center;
}
.smLink{
    color: white;
}
.smLink:hover{
    color: #009688;
}