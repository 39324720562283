@import url('https://fonts.googleapis.com/css2?family=Domine&family=Kanit:wght@300;400&family=Roboto+Slab&family=Rubik&display=swap');

.caption{
    position: absolute;
    top: 53%;
    left: 27%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 35%;
}   
/* .sliderImg{
    opacity: 0.6;
}   */
.MuiTypography-root.imgText{
    margin: 20px 0;
    font-weight: 900;
    font-size: 27px;
    color: white;
    letter-spacing: 2px;
    word-spacing: 3px;
    line-height: 41px;
    font-family: 'Kanit', sans-serif;
}
/* font-family: 'Domine', serif;
font-family: 'Kanit', sans-serif;
font-family: 'Roboto Slab', serif;
font-family: 'Rubik', sans-serif; */

/* font-family: 'Blinker', sans-serif;
font-family: 'Bungee Inline', cursive;
font-family: 'Days One', sans-serif;
font-family: 'IBM Plex Sans Thai Looped', sans-serif;
font-family: 'Lexend', sans-serif;
font-family: 'Moul', cursive; */


/* font-family: 'Archivo', sans-serif;
font-family: 'Audiowide', cursive;
font-family: 'Bai Jamjuree', sans-serif;
font-family: 'Bakbak One', cursive;
font-family: 'Chivo', sans-serif;
font-family: 'Kanit', sans-serif;
font-family: 'Lemon', cursive;
font-family: 'Oxanium', cursive;
font-family: 'Russo One', sans-serif; */
.css-1m9128y{
    width: 100%;
    margin-top: 10px;
    text-align: center;
    position: absolute;
    z-index: 50;
    bottom: 25px;
}
.MuiButtonBase-root.checkButton{
    background-color: #009688;
}
.MuiButtonBase-root.checkButton:hover{
    background-color: #00695f;
}