.careerContent {
    margin-top: 100px;
}

.headingTitle {
    width: 100%;
    background-color: #00000059;
    padding: 31px 0;
    box-sizing: border-box;
    color: whitesmoke;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
.headingText{
    
    /* style={{fontSize: "65px", textAlign: "center", margin: 0,padding:'0 15px 0 30px' }} */
    text-align: center;
    margin: 0;
    padding: 0 15px 0 30px;
    box-sizing: border-box;
}

.careerBody {
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    padding-top: 20px;
}

.carImgBody {
    padding: 20px 0 0 20px;
}
.keyDownArrow{
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    color: #00695f;
}

.ArrowIcon {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid lightgray;
    /* padding: 10px;
    font-size: larger;
    font-weight: bold;
    color: black;
    background-color: rgb(210, 209, 209);
    border: 2px solid white; */
}

.ArrowIcon:hover {
    padding: 10px;
    font-size: larger;
    font-weight: bold;
    color: black;
    background-color: rgb(164, 164, 164);
    border-radius: 50%;
    border: 2px solid rgb(164, 164, 164);
    box-shadow: 2px 2px 5px #606061;
}

/*Appy Online Form*/
.ApplyOnline {
    background-color: #009688;
    text-align: center;
    padding: 50px 0 50px 0px;
    margin-top: 50px;
    border-radius: 30px;       
    color:white;               
}

.ApplyOnlineForm{
    padding: 50px 20px 0px;   
    background-color: #009688; 
}

.MuiFormControl-root.controlFormApplyOnline {
    margin: 0 15px 15px 0;
    border: 0;
    width: 100%;
    background-color: white;
    border-radius: 6px; 
}

.MuiFormControl-root.controlFormMessage {
    margin: 0 15px 15px 0;
    border: 0px;
    width: 100%;
    background-color: white;
    border-radius: 6px;
}

.MuiButtonBase-root.butOnSubmit {
    background-color: #0d222d;
    width: 100px;
    font-weight: bolder;
}

.MuiButtonBase-root.butOnSubmit:hover {
    background-color: #00695f;
}
.careerGridBox{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    justify-content: space-between;
    padding: 30px;
    box-sizing: border-box;
    background-color: #009688;
    border-radius: 10px;
    color: white;
}
.gridPadding{
    padding-top: 100px;
    box-sizing: border-box;
    width: 100%;
}
