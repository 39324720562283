@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Georgian:wght@500&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');

*{
  margin: 0;
  padding: 0;
  
}
html{
  scroll-behavior: auto!important;
}

.headingBox{
    background-color: #00695f;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    color: white;
}

.gridBox{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    justify-content: space-between;
    padding: 30px;
    box-sizing: border-box;
    background-color: #009688;
    border-radius: 10px;
}
p{
    font-family: 'Poppins', sans-serif!important;
}
h5{
    font-family: 'Roboto Slab', serif!important;
    letter-spacing: 0.7px!important;
    text-decoration: underline;
    text-underline-offset:6px;
    margin-bottom: 30px!important;
}
h1{
  font-family: "Noto Serif Georgian"!important;
}


