.headingBox{
    background-color: #00695f;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    color: white;
}

.gridBox{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    justify-content: space-between;
    padding: 30px;
    box-sizing: border-box;
    background-color: #009688;
    border-radius: 10px;
    color: white;
    row-gap: 40px;
}
.aboutImg{
    width: 100%;
    height: 95%;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    margin-top: 9px;
}
/* .animateText{
    animation: gridText 2s;
} */
