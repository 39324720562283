




@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@600&family=Bungee+Inline&family=Days+One&family=IBM+Plex+Sans+Thai+Looped:wght@600;700&family=Lexend&family=Moul&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@600&display=swap');  
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@500;600;700&family=Audiowide&family=Bai+Jamjuree:wght@600;700&family=Bakbak+One&family=Chivo:wght@500;600&family=Kanit:wght@500;600&family=Lemon&family=Oxanium:wght@500;600;700&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@700&display=swap');
nav a{
    text-decoration: none;
    padding: 8px;
    box-sizing: border-box;
    color: #00695f;
    font-family: 'Lexend', sans-serif;
    letter-spacing: 1px;
    display: block;
    width: 100%;
}
a:hover{
    color:#00695f;
}
nav a:active,.navList a:active{
    color: #00695f;
    border-radius: 8px;
}
.sideBarItem:active{
    border-radius: 0px!important;
}

nav a.active,.navList a.active{
    background-color: #009688;
    color: white;
    border-radius: 8px;
}
.navList a.active{
    background-color: #009688;
    color: white;
    border-radius: 0;
}
.navList a{
    line-height: 35px;
    text-decoration: none;
    font-weight: bold;
    color:#00695f;
    width: 100%;
    padding: 8px 16px;
    box-sizing: border-box;
    font-family: 'Lexend', sans-serif;
    letter-spacing: 1px;
}

.MuiTypography-root.titleText{
    font-family: 'Bai Jamjuree', sans-serif;
    letter-spacing: 0.7px;
    color:#123a4d;   
    display: flex;
    flex-direction: column;
    line-height: 24px;
    margin-top: 9px;
}
.MuiList-root.navList{
    padding-top: 0;
    padding-bottom: 0;
}

.listButton .Mui-selected{
    background-color: white!important;
}
.dropdown-toggle::after{
    content:none;
}
.dropdown-toggle-split {
    padding-right: 0px;
    padding-left: 0px;
}
.dropdownMenu{
    margin-top: 5px;
    background-color: whitesmoke;
}
.dropdown-item{
    padding: 3px;
    box-sizing: border-box;
}
/* .dropdown-item:hover{
    background-color: #00695f;
    color: white;
} */
.dropdown-item.active{
    background-color: #00695f;
    color: white;
}
.dropdown-item:active{
   background-color: #ddfffc;
}

nav a:active, .navList a:active{
    color: white;
    background-color: #00695f;
}
.css-1j72ahl-MuiButtonBase-root-MuiListItemButton-root:hover{
    text-decoration: none;
    background-color: rgb(0 0 0 / 11%);
}
.css-tq6qta-MuiButtonBase-root-MuiButton-root:hover{
    text-decoration: none;
    background-color: rgb(0 0 0 / 11%);
}
.MuiButtonBase-root.listBtnHover:hover{
    text-decoration: none;
    background-color: rgb(0 0 0 / 11%);
    border-radius: 8px;
}
.MuiButtonBase-root.listBtnHover1:hover{
    text-decoration: none;
    background-color: rgb(0 0 0 / 11%);
    border-radius: 0px;
}
.dropdown-item:hover{
    text-decoration: none;
    background-color: rgb(0 0 0 / 11%);
}


/* font-family: 'Blinker', sans-serif;
font-family: 'Bungee Inline', cursive;
font-family: 'Days One', sans-serif;
font-family: 'IBM Plex Sans Thai Looped', sans-serif;
font-family: 'Lexend', sans-serif;
font-family: 'Moul', cursive; */


/* font-family: 'Archivo', sans-serif;
font-family: 'Audiowide', cursive;
font-family: 'Bai Jamjuree', sans-serif;
font-family: 'Bakbak One', cursive;
font-family: 'Chivo', sans-serif;
font-family: 'Kanit', sans-serif;
font-family: 'Lemon', cursive;
font-family: 'Oxanium', cursive;
font-family: 'Russo One', sans-serif; */